import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Button, Form, Col, Row, Card } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import * as Yup from "yup";

import 'bootstrap/dist/css/bootstrap.min.css';

import _ from "lodash";

import '../../i18n';
import { validateCPF } from '../../functions/validate';

import countryList from './country-list';
import stateList from './state-list';
import FormInt from './form-int';
interface FormInputProps {
  formSubmit: (values: any) => void;
  f: any;
}

const FormPF: React.FC<FormInputProps> = ({formSubmit, f}) => {
  const { t } = useTranslation();

  const [mask, setMask] = useState("(99)99999-9999");
  const [autoCompleting, setAutoCompleting] = useState(false);

  const countries = countryList;
  const states = stateList;
  
  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      name: '',
      cpf_cnpj: '',
      rg: '',
      birthdate: '',
      account_type: '',
      email2: '',
      ddi: 55,
      mobile: '',
      branch: '',
      country: 'Brazil',
      postalcode: '',
      address: '',
      number: '',
      neighborhood:'',
      address_complement: '',
      city: '',
      state: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(10, 'O nome precisa ter ao menos 10 caracteres')
        .required(t('validations:requiredField'))
        .test('countWords', 'Preencha o nome completo', val => {
          const value = val || '';
          const match = value.match(/\S+/g) || []
          return match.length > 1 
        }), 
      cpf_cnpj: Yup.string()
        .ensure().when('country', {
          is: 'Brazil',
          then: Yup.string().required(t('validations:requiredField')).test('validCpf', t('validations:invalidCpf'), (val: string | undefined): boolean => 
            typeof val === 'string' ? validateCPF(val) : false)
        }),
      rg: Yup.string()
        .ensure().when('country', {
          is: 'Brazil',
          then: Yup.string().required(t('validations:requiredField'))
        }),
      birthdate: Yup.string()
        .required(t('validations:requiredField')),
      account_type: Yup.string()
        .required(t('validations:requiredField')),
      email2: Yup.string()
        .required(t('validations:requiredField'))
        .test('email2', 'O email alternativo não pode ser o mesmo do email principal', (val) => {
          var formData = new FormData(f.current);
          const email1 = Object.fromEntries(formData).email1;
          return email1 !== val 
        })
        .email(t('validations:invalidEmail')),
      ddi: Yup.string()
        .required(t('validations:requiredField'))
        .test('validmobile', t('validations:invalidPhone'), val =>
        /^[0-9]+$/.test(val || '')),
      mobile: Yup.string()
        .required(t('validations:requiredField'))
        .test('validmobile', t('validations:invalidPhone'), val => /\([1-9]\d\)9?\d{4}|d{5}-\d{4}$/.test(val || '')),
      country: Yup.string()
        .required(t('validations:requiredField')),
      postalcode: Yup.string()
        .required(t('validations:requiredField'))
        .test('validZipCode', t('validations:invalidZipCode'), val =>
        /\d{5}-\d{3}$/.test(val || '')),
      address: Yup.string()
        .required(t('validations:requiredField')),
      number: Yup.number()
        .required(t('validations:requiredField')),
      neighborhood: Yup.string()
        .required(t('validations:requiredField')),
      city: Yup.string()
        .required(t('validations:requiredField')),
      state: Yup.string()
        .required(t('validations:requiredField')),
    }),
    onSubmit: (values: any) => {
      formSubmit(values);
    },
  });

  const baseURL = "https://asn.apihn.co/api";

  const handleCep = (e: any, setFieldValue: any) => {
    const cep = e.target.value.replace(/\D/g, "");
    
    if(cep.length !== 8) {
      return;
    }
    setAutoCompleting(true);
    axios.get(`${baseURL}/cep?cep=${cep}`)
      .then(function (response) {
        const estadoInfo = stateList.find(s => s.sigla === response.data.uf) || { 'sigla': '' };

        // manipula o sucesso da requisição
        setFieldValue('address', response.data.end);
        setFieldValue('neighborhood', response.data.bairro);
        setFieldValue('city', response.data.cidade);
        setFieldValue('state', estadoInfo.sigla);
      })
      .catch(function (error) {
        // manipula erros da requisição
        console.error(error);
      })
      .then(function () {
        // sempre será executado
        setAutoCompleting(false);
      });      
  }

  const [isShown, setIsShown] = useState(true);
  const [country, setCountry] = useState('Brazil');
  
  const handleShowHide = (e: any, setFieldValue: any) => {
    const getCountry = e.target.value;
    setCountry(getCountry);
    
    { getCountry !== 'Brazil' ? setIsShown(false) : setIsShown(true) }

    setFieldValue('country', e.target.value);
  }

  return (
    <>
      {isShown?<><Card className="m-3 rounded-0">
        <Card.Body className="custom-bg-card">
          <Form onSubmit={formik.handleSubmit} id="formEmailClient">
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="name" className="col-form-label-sm">Nome completo</Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    isInvalid={!!formik.touched.name && !!formik.errors.name}
                    disabled={formik.isSubmitting}
                    className="form-control form-control-sm rounded-0" />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                
                {/* {isShown && country === 'Brazil' && (
                  <Row className="mb-3">
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="cpf_cnpj" className="col-form-label-sm">CPF</Form.Label>
                      <Form.Control
                        as={InputMask} 
                        id="cpf_cnpj"
                        name="cpf_cnpj"
                        mask="999.999.999-99"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cpf_cnpj}
                        disabled={formik.isSubmitting}
                        isInvalid={!!formik.touched.cpf_cnpj && !!formik.errors.cpf_cnpj}
                        className="form-control form-control-sm rounded-0" />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.cpf_cnpj}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col-md">
                      <Form.Label htmlFor="rg" className="col-form-label-sm">RG</Form.Label>
                      <Form.Control
                        id="rg"
                        name="rg"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.rg}
                        disabled={formik.isSubmitting}
                        isInvalid={!!formik.touched.rg && !!formik.errors.rg}
                        className="form-control form-control-sm rounded-0" />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.rg}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                )} */}
                <Row className="mb-3">
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor="cpf_cnpj" className="col-form-label-sm">CPF</Form.Label>
                      <Form.Control
                        as={InputMask} 
                        id="cpf_cnpj"
                        name="cpf_cnpj"
                        mask="999.999.999-99"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cpf_cnpj}
                        disabled={formik.isSubmitting}
                        isInvalid={!!formik.touched.cpf_cnpj && !!formik.errors.cpf_cnpj}
                        className="form-control form-control-sm rounded-0" />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.cpf_cnpj}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col-md">
                      <Form.Label htmlFor="rg" className="col-form-label-sm">RG</Form.Label>
                      <Form.Control
                        id="rg"
                        name="rg"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.rg}
                        disabled={formik.isSubmitting}
                        isInvalid={!!formik.touched.rg && !!formik.errors.rg}
                        className="form-control form-control-sm rounded-0" />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.rg}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} className="mb-3 mb-md-0">
                    <Form.Label htmlFor="birthdate" className="col-form-label-sm">Nascimento</Form.Label>
                    <Form.Control
                      id="birthdate"
                      name="birthdate"
                      type="date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.birthdate}
                      disabled={formik.isSubmitting}
                      isInvalid={!!formik.touched.birthdate && !!formik.errors.birthdate}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.birthdate}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-md-0">
                    <Form.Label htmlFor="account_type" className="col-form-label-sm">Tipo de conta</Form.Label>
                    <Form.Select
                      id="account_type"
                      name="account_type"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.account_type}
                      disabled={formik.isSubmitting}
                      isInvalid={!!formik.touched.account_type && !!formik.errors.account_type}
                      className="form-select form-select-sm rounded-0" >
                          <option value="">Selecione o tipo de conta</option>
                          <option value="DONO" label="Dono">Sou dono desta conta</option>
                          <option value="DESENVOLVEDOR" label="Desenvolvedor">Sou desenvolvedor desta conta</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.account_type}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="email2" className="col-form-label-sm">Email alternativo</Form.Label>
                  <Form.Control
                    id="email2"
                    name="email2"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email2}
                    disabled={formik.isSubmitting}
                    isInvalid={!!formik.touched.email2 && !!formik.errors.email2}
                    className="form-control form-control-sm rounded-0" />
                  <Form.Control.Feedback type="invalid">
                      {formik.errors.email2}
                    </Form.Control.Feedback>
                </Form.Group>

                <Row>
                  <Form.Group as={Col} sm={3} className="mb-3 mb-md-0">
                    <Form.Label htmlFor="ddi" className="col-form-label-sm">DDI</Form.Label>
                    <Form.Control
                      id="ddi"
                      name="ddi"
                      type="string"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value="55"
                      disabled={true}
                      isInvalid={!!formik.touched.ddi && !!formik.errors.ddi}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.ddi}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-3 mb-md-0">
                    <Form.Label htmlFor="mobile" className="col-form-label-sm">Telefone</Form.Label>
                    <Form.Control
                      as={InputMask}
                      id="mobile"
                      name="mobile"
                      type="string"
                      mask={mask}
                      onChange={formik.handleChange}
                      disabled={formik.isSubmitting}
                      onBlur={e => {
                          if (e.target.value.replace("_", "").length === 14 && e.target.value[4] === '9') {
                            setMask("(99)99999-9999");
                          } else if (e.target.value.replace("_", "").length === 13 && e.target.value[4] !== '9') {
                            setMask("(99)9999-9999");
                          }
                      }}
                      onFocus={e => {
                          if (e.target.value.replace("_", "").length) {
                            setMask("(99)99999-9999");
                          }
                      }}
                      value={formik.values.mobile}
                      isInvalid={!!formik.touched.mobile && !!formik.errors.mobile}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.mobile}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/* {isShown && country === 'Brazil' && (
                    <Form.Group as={Col} sm={4}>
                      <Form.Label htmlFor="branch" className="col-form-label-sm">Ramal</Form.Label>
                      <Form.Control
                        id="branch"
                        name="branch"
                        type="string"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={formik.isSubmitting}
                        value={formik.values.branch}
                        className="form-control form-control-sm rounded-0" />
                    </Form.Group>
                  )} */}
                  
                </Row>
              </Col>

              <Col>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="country" className="col-form-label-sm">País</Form.Label>
                  <Form.Select
                    id="country"
                    name="country"
                    onChange={(e)=>(handleShowHide(e, formik.setFieldValue))}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                    disabled={formik.isSubmitting}
                    isInvalid={!!formik.touched.country && !!formik.errors.country}
                    className="form-select form-select-sm rounded-0" >
                      {countries.map(country => (
                        <option key={country.idPais} value={country.paisEn}>
                          {country.pais}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.country}
                  </Form.Control.Feedback>
                </Form.Group>

                <Row className="mb-3">
                  <Form.Group className="col-md mb-3 mb-md-0">
                    <Form.Label htmlFor="postalcode" className="col-form-label-sm">CEP</Form.Label>
                    <Form.Control
                      as={InputMask}
                      id="postalcode"
                      name="postalcode"
                      type="string"
                      mask="99999-999"
                      disabled={formik.isSubmitting}
                      onChange={formik.handleChange}
                      onBlur={(e) => handleCep(e, formik.setFieldValue)}
                      value={formik.values.postalcode}
                      isInvalid={!!formik.touched.postalcode && !!formik.errors.postalcode}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.postalcode}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="col-md mb-md-0">
                    <Form.Label htmlFor="address" className="col-form-label-sm">Endereço</Form.Label>
                    <Form.Control
                      id="address"
                      name="address"
                      type="string"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting || autoCompleting}
                      value={formik.values.address}
                      isInvalid={!!formik.touched.address && !!formik.errors.address}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group className="col-md mb-3 mb-md-0">
                    <Form.Label htmlFor="number" className="col-form-label-sm">Número</Form.Label>
                    <Form.Control
                      id="number"
                      name="number"
                      type="string"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.number}
                      disabled={formik.isSubmitting}
                      isInvalid={!!formik.touched.number && !!formik.errors.number}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.number}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label htmlFor="address_complement" className="col-form-label-sm">Complemento</Form.Label>
                    <Form.Control
                      id="address_complement"
                      name="address_complement"
                      type="string"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={formik.isSubmitting}
                      value={formik.values.address_complement}
                      className="form-control form-control-sm rounded-0" />
                  </Form.Group>
                </Row>

                <Form.Group as={Col} className="mb-3">
                  <Form.Label htmlFor="neighborhood" className="col-form-label-sm">Bairro</Form.Label>
                  <Form.Control
                    id="neighborhood"
                    name="neighborhood"
                    type="string"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.neighborhood}
                    disabled={formik.isSubmitting || autoCompleting}
                    isInvalid={!!formik.touched.neighborhood && !!formik.errors.neighborhood}
                    className="form-control form-control-sm rounded-0" />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.neighborhood}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label htmlFor="city" className="col-form-label-sm">Cidade</Form.Label>
                  <Form.Control
                    id="city"
                    name="city"
                    type="string"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                    disabled={formik.isSubmitting || autoCompleting}
                    isInvalid={!!formik.touched.city && !!formik.errors.city}
                    className="form-control form-control-sm rounded-0" />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.city}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* {isShown && country === 'Brazil' && (
                  <Form.Group>
                    <Form.Label htmlFor="state" className="col-form-label-sm">Estado</Form.Label>
                    <Form.Select
                      id="state"
                      name="state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                      disabled={formik.isSubmitting || autoCompleting}
                      isInvalid={!!formik.touched.state && !!formik.errors.state}
                      className="form-select form-select-sm rounded-0" >
                        {states.map(state => (
                          <option key={state.idUf} value={state.sigla}>
                            {state.estado}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                )} */}
                <Form.Group>
                    <Form.Label htmlFor="state" className="col-form-label-sm">Estado</Form.Label>
                    <Form.Select
                      id="state"
                      name="state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                      disabled={formik.isSubmitting || autoCompleting}
                      isInvalid={!!formik.touched.state && !!formik.errors.state}
                      className="form-select form-select-sm rounded-0" >
                        {states.map(state => (
                          <option key={state.idUf} value={state.sigla}>
                            {state.estado}
                          </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>

                {/* {!isShown && country !== 'Brazil' && (
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="city" className="col-form-label-sm">Estado</Form.Label>
                    <Form.Control
                      id="state"
                      name="state"
                      type="string"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                      disabled={formik.isSubmitting}
                      isInvalid={!!formik.touched.state && !!formik.errors.state}
                      className="form-control form-control-sm rounded-0" />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                )} */}
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Card className="m-3 rounded-0">
        <Card.Body className="custom-bg-card text-secondary custom-font-size-sm">
          <Card.Text className="mb-2">
            <span className="ajuda"></span><span>Insira o CPF, RG, CEP e demais números para contato sem pontuação ou traços.</span>
          </Card.Text>
          <Card.Text className="mb-2">
            <span className="ajuda"></span><span>Utilize sempre dois endereços de e-mail, o principal e o alternativo (Gmail, Hotmail e outros). Desta forma garantirá o recebimento de todos os nossos comunicados.</span>
          </Card.Text>
          <Card.Text className="mb-2">
            <span className="ajuda"></span><span>Optando pela forma de pagamento via boleto bancário, ele será enviado aos e-mails de cadastro.</span>
          </Card.Text>
        </Card.Body>
      </Card>
      <Button
        form='formEmailClient'
        variant="warning"
        type="submit"
        size="lg"
        className="mx-3 mb-3 nextStep" >Enviar
      </Button></>:<FormInt formSubmit={formSubmit} f={f} setIsShown={setIsShown} formikParent={formik}/>}
    </>);
};

export default FormPF;