import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

interface SendCodeButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  sendCode: () => Promise<boolean>;
}

const VerificationCodeButton: React.FC<SendCodeButtonProps> = ({
  sendCode,
  ...props
}) => {
  const [sending, setSending] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [alreadySent, setAlreadySent] = useState(false);

  const handleRequestCode = async () => {
    setSending(true);

    const sent = await sendCode();

    if (sent) {
      setCooldown(30); 
      setAlreadySent(true);
    }

    setSending(false);
  };

  useEffect(() => {
    if (cooldown === 0) {
      return;
    }

    const interval = setInterval(() => {
      setCooldown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [cooldown]);

  function getButtonText() {
    if (sending) {
      return "Enviando...";
    }
  
    if (cooldown > 0) {
      return `Poderá reenviar em ${cooldown}s`;
    }

    if (alreadySent) {
        return "Reenviar código";
    }
  
    return "Enviar código";
  }

  return (
    <Button
      type="button"
      variant={cooldown > 0 ? "secondary" : "primary"}
      onClick={handleRequestCode}
      disabled={cooldown > 0 || sending}
      {...props}
    >
      {getButtonText()}
    </Button>
  );
};

export default VerificationCodeButton;
